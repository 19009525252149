var render = function render(){var _vm=this,_c=_vm._self._c;return _c('w-layout',[_c('w-flex',[_c('v-textarea',{attrs:{"no-resize":"","rows":"10","counter":_vm.rules.descriptionLength,"color":"primary","label":_vm.descriptionFieldName,"rules":[
				value => {
					let result = true
					if (!value || value.trim().length > _vm.rules.descriptionLength) {
						result = _vm.descriptionValidationErrorMessage
					}
					return result
				}
			]},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }