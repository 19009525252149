<template>
	<w-layout>
		<w-flex>
			<v-textarea
				v-model="description"
				no-resize
				rows="10"
				:counter="rules.descriptionLength"
				color="primary"
				:label="descriptionFieldName"
				:rules="[
					value => {
						let result = true
						if (!value || value.trim().length > rules.descriptionLength) {
							result = descriptionValidationErrorMessage
						}
						return result
					}
				]"
			></v-textarea>
		</w-flex>
	</w-layout>
</template>

<script>
export default {
	name: 'MobileAppDescription',
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			modified: false,
			rules: {
				descriptionLength: 4000
			}
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		description: {
			get: function () {
				let result = null
				if (this.modified) {
					result = this.application.description
				} else {
					result = this.application.description.replace(/%AppName%/g, this.application.title)
				}
				return result
			},
			set: function (val) {
				this.modified = true
				this.application.description = val
			}
		},
		descriptionFieldName: function () {
			return this.$t('mobileapp.fields.description')
		},
		descriptionValidationErrorMessage: function () {
			return this.$tc('mobileapp.rules.field.maxlen', 1, {
				fieldname: this.descriptionFieldName.toLowerCase(),
				max: this.rules.descriptionLength
			})
		}
	}
}
</script>
